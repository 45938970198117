import { template as template_94f2139d1f634561a536d91c1cab4b65 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import IconPicker from "select-kit/components/icon-picker";
export default class FKControlIcon extends Component {
    static controlType = "icon";
    @action
    handleInput(value) {
        this.args.field.set(value);
    }
    static{
        template_94f2139d1f634561a536d91c1cab4b65(`
    <IconPicker
      @value={{readonly @field.value}}
      @onlyAvailable={{true}}
      @options={{hash
        maximum=1
        disabled=@field.disabled
        caretDownIcon="angle-down"
        caretUpIcon="angle-up"
        icons=@field.value
      }}
      @onChange={{this.handleInput}}
      class="form-kit__control-icon"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
