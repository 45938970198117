import { template as template_b92c32b1344b43a58a4685a2b988f874 } from "@ember/template-compiler";
const WelcomeHeader = template_b92c32b1344b43a58a4685a2b988f874(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
