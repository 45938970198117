import { template as template_60e1f5d961f94881986b4a88bcf97674 } from "@ember/template-compiler";
const FKLabel = template_60e1f5d961f94881986b4a88bcf97674(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
