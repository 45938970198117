import { template as template_2592f3e2a76f485d8152ae55f7cba170 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_2592f3e2a76f485d8152ae55f7cba170(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
