import { template as template_3e1ce9cdd03146b6bd614c26c0c3c114 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3e1ce9cdd03146b6bd614c26c0c3c114(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
