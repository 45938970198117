import { template as template_47a8735824b54ac0a35979be24fbabb1 } from "@ember/template-compiler";
const SidebarSectionMessage = template_47a8735824b54ac0a35979be24fbabb1(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
